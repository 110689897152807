.Input {
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 5px;
}

.Input-medium {
  font-size: 26pt;
}

.Input-large {
  font-size: 44pt;
}
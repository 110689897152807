.EventPage {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 0 50px;
  min-height: 100%;
}

.EventPage-background {
  opacity: .4;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.EventPage-hasEnded {
  text-align: center;
  padding: 20px 0;
}
.Button,
.Icon {
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
}

.Button {
  border-radius: 15px;
  background-color: #009FB7;
  color: white;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
}

.Button--small {
  font-size: 14pt;
}

.Button--medium {
  font-size: 20pt;
}

.Button--large {
  font-size: 32pt;
}

.Button--secondary {
  background-color: rgba(0, 0, 0, .2);
}

.Button--disabled {
  background-color: #EFF1F3!important;
}

.Button-icon {
  display: inline;
  margin-right: 8px;
}

.Icon {
  width: 40px;
  height: 40px;
  border-radius: 50vh;
}

.Icon--small {
  width: 25px;
  height: 25px;
}
.PlaceSuggestions {
  display: flex;
  flex-direction: column;
}

.PlaceSuggestions-header {
  padding: 0 0 5px;
  margin: 0;
}

.PlaceSuggestion {
  margin: 4px 0;
}
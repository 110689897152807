.EventCodeForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 30px 0;
}

.EventCodeForm > * {
  margin: 10px 0;
}
.TravelModeSelect {
  color: black;
}

.TravelModeSelect-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.TravelModeSelect-item {
  padding: 10px;
  color: #696773;
  border: none;
  background-color: transparent;
}

.TravelModeSelect-item--selected {
  color: #009FB7;
}

.TravelModeSelect-bar {
  height: 4px;
}

.TravelModeSelect-loading {
  margin-top: 3px;
  border-radius: 2px;
  background-color: #009FB7;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index:100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
body {
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  color: white;
  background: rgb(34, 34, 34) no-repeat;
}

html {
  background-size: cover;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

a {
  color: white;
  text-decoration: none;
}

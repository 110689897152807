.AttendeesList {
  display: flex;
  flex-direction: column;
}

.AttendeesList-modal {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 -2px 0 rgba(255,255,255,.05);
  background-color: rgb(34, 34, 34);
  position: absolute!important;
  bottom: 0;
  left: 0;
  padding: 10px 0 100px 0;
  height: calc(100vh - 110px - 108px - 400px); /* Subtract padding, header, and carousel */
  min-height: 50px;
  width: 100%;
  animation: .2s 1 alternate slideInFromBelow;
}

.AttendeesList-modal-overlay {
  background: none;
}

.AttendeesList-selected {

}

@keyframes slideInFromBelow {
  0% {bottom: -100%;}
  100% {bottom: 0;}
}
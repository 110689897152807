.AttendeesListItem {
  padding: 5px;
  border-radius: 8px;
  background-color: rgba(255,255,255,.2);
  display: flex;
  flex-direction: row;
  margin: 3px 5px;
}

.AttendeesListItem-content {
  flex-grow: 1;
}

.AttendeesListItem-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AttendeesListItem-name {
  font-weight: normal;
  font-size: 14pt;
  margin: 0;
  padding: 2px 0;
}

.AttendeesListItem-eta {
  align-self: center;
  font-size: 11pt;
}
.EventDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.EventDetails-title,
.EventDetails-subtitle {
  text-align: center;
}

.EventDetails-subtitle > * {
  padding: 0 8px;
}

.EventDetails-scheduledTime {
  color: rgb(247, 204, 70);
}

.EventDetails-title {
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  margin: 5px;
}

.EventDetails-dateTime {
  min-width: 35vw;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.EventDetails-time {
  font-size: 19pt;
}

.EventDetails-title:empty {
  min-width: 30px;
  min-height: 20px;
  border-radius: 15px;
  animation: pulse 1s infinite ease-in-out;
}

.EventDetails-title:empty {
  width: 200px;
  height: 50px;
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.1)
  }
  50% {
    background-color: rgba(255, 255, 255, 0.3)
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1)
  }
}
.AttendeesListItemIcon {
  position: relative;
  border: none;
  outline: none;
  border-radius: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 10vw;
  width: 10vw;
  max-width: 10vw;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
  font-size: 125%;
  background-color: rgba(255, 255, 255, .4);
  margin: 0 10px 0 0;
}
.AttendeesListItem {
  padding: 5px;
  border-radius: 8px;
  background-color: rgba(255,255,255,.2);
  display: flex;
  flex-direction: row;
  margin: 3px 5px;
}

.AttendeesListItem-content {
  flex-grow: 1;
}

.AttendeesListItem-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AttendeesListItem-name {
  font-weight: normal;
  font-size: 14pt;
  margin: 0;
  padding: 2px 0;
}

.AttendeesListItem-eta {
  align-self: center;
  font-size: 11pt;
}
.LastUpdatedTime {
  font-size: 9pt;
  height: 20px;
  color: #bfbfbf;
  display: flex;
}

.LastUpdatedTime-refreshing {
  flex-grow: 1;
  padding: 0 10px;
}

.LastUpdatedTime-refreshing > * {
  position: relative;
  height: 100%;
  display: inline-block;
  word-spacing: -8px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.LastUpdatedTime-refreshing--driving {
  -webkit-animation: travelling 2.2s linear infinite;
          animation: travelling 2.2s linear infinite;
}

.LastUpdatedTime-refreshing--transit {
  -webkit-animation: travelling-transit 1.6s linear infinite;
          animation: travelling-transit 1.6s linear infinite;
}

.LastUpdatedTime-refreshing--walking {
  -webkit-animation: travelling 5.5s linear infinite;
          animation: travelling 5.5s linear infinite;
}

@-webkit-keyframes travelling {
  0% {left: 0;}
  100% {left: 88%;}
}

@keyframes travelling {
  0% {left: 0;}
  100% {left: 88%;}
}

@-webkit-keyframes travelling-transit {
  0% {left: 0;}
  100% {left: 60%;}
}

@keyframes travelling-transit {
  0% {left: 0;}
  100% {left: 60%;}
}
.Button,
.Icon {
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
}

.Button {
  border-radius: 15px;
  background-color: #009FB7;
  color: white;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
}

.Button--small {
  font-size: 14pt;
}

.Button--medium {
  font-size: 20pt;
}

.Button--large {
  font-size: 32pt;
}

.Button--secondary {
  background-color: rgba(0, 0, 0, .2);
}

.Button--disabled {
  background-color: #EFF1F3!important;
}

.Button-icon {
  display: inline;
  margin-right: 8px;
}

.Icon {
  width: 40px;
  height: 40px;
  border-radius: 50vh;
}

.Icon--small {
  width: 25px;
  height: 25px;
}
.Banner {
  background-color: red;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ErrorBanner-message {
  color: white;
  display: inline-flex;
  align-items: center;
}
.Input {
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 5px;
}

.Input-medium {
  font-size: 26pt;
}

.Input-large {
  font-size: 44pt;
}
.EventCodeForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 30px 0;
}

.EventCodeForm > * {
  margin: 10px 0;
}
.RecentEvents {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.RecentEvent {
  margin: 5px 0;
 }
.Tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Tab-text {
  color: white;
  text-decoration: none;
  font-size: 8pt;
  color: #808080;
}

.Tab-text--active {
  color: white;
}

.Tab > a {
  text-decoration: none;
}
.NavBar {
  margin: 0 !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 45px;
  width: 100%;
  background-color: #292929;
  z-index: 1001; /* Higher than the selected Attendee modal */
}

.NavBar > * {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header {
  display: flex;
  min-height: 50px;
  max-height: 50px;
}

.Header > * {
  width: 100%;
}
.PlacesAutocomplete-wrapper {
  display: flex;
  margin: 10px;
  padding: 0;
}

.PlacesAutocomplete-root {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.PlacesAutocomplete-input {
  border-radius: 10px;
  font-size: 12pt;
  padding: 7px;
  background-color: white;
  text-align: left;
  color: grey;
}

.PlacesAutocomplete-input,
.PlacesAutocomplete-autocompleteItem {
  border: none;
  outline: none;
}

.PlacesAutocomplete-autocompleteItem {
  color: black;
  font-size: 10pt;
  padding: 4px 0;
}

.PlacesAutocomplete-input {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
}

.PlacesAutocomplete-autocompleteContainer {
  margin-top: 10px;
  max-height: 0;
}

.PlacesAutocomplete-autocompleteContainer--nonEmpty,
.PlaceSuggestions {
  max-height: 500px;
  -webkit-transition: max-height 0.25s ease-in;
  transition: max-height 0.25s ease-in;
}

.PlacesAutocomplete-autocompleteItemActive {
  background-color: #d3d3d3;
}

.PlacesAutocomplete-autocompleteItem-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.PlacesAutocomplete-autocompleteItem-mainText {
}

.PlacesAutocomplete-autocompleteItem-secondaryText {
  font-size: 10pt;
  color: lightgrey;
}
.HomePage {

}
.LoginForm {

}

.LoginForm-button {
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
  outline: none;
  border-radius: 5px;
  color: white;
  padding: 10px 0;
  width: 200px;
  display: flex;
  justify-content: center;
}

.LoginForm-button--google {
  background-color: lightgrey;
  color: black;
}

.LoginForm-button-text {
  font-size: 13pt;
}
.EventsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.PlaceSuggestions {
  display: flex;
  flex-direction: column;
}

.PlaceSuggestions-header {
  padding: 0 0 5px;
  margin: 0;
}

.PlaceSuggestion {
  margin: 4px 0;
}
.EventCreateForm {
  display: flex;
  flex-direction: column;
}

.EventCreateForm > * {
  margin: 10px;
}
.EventSettingEndEvent-message {
  color: black;
  font-size: 24pt;
  margin-bottom: 10px;
}

.EventSettingEndEvent-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.EventSettings {
  position: absolute;
  top: 10px;
  right: 10px;
}

.EventSettings-modal {
  position: absolute!important;
  top: 0;
  left: 0;
  padding: 0!important;
  width: 100%;
  -webkit-animation: .5s 1 alternate slideInFromAbove;
          animation: .5s 1 alternate slideInFromAbove;
}

.EventSettings-dropdown {
  display: flex;
  flex-direction: column;
}

.EventSettings-dropdownItem {
  margin: 7px;
}

.EventSettings-dropdownItem > button {
  width: 100%;
}

@-webkit-keyframes slideInFromAbove {
  0% {top: -100%;}
  100% {top: 0;}
}

@keyframes slideInFromAbove {
  0% {top: -100%;}
  100% {top: 0;}
}
.TimeSelect {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.TimeSelect-inputArea {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.TimeSelect-contentArea {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.TimeSelect-toDate {
  font-size: 14pt;
}

.TimeSelect .Input {
  width: 40%;
}
.EventDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.EventDetails-title,
.EventDetails-subtitle {
  text-align: center;
}

.EventDetails-subtitle > * {
  padding: 0 8px;
}

.EventDetails-scheduledTime {
  color: rgb(247, 204, 70);
}

.EventDetails-title {
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  margin: 5px;
}

.EventDetails-dateTime {
  min-width: 35vw;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.EventDetails-time {
  font-size: 19pt;
}

.EventDetails-title:empty {
  min-width: 30px;
  min-height: 20px;
  border-radius: 15px;
  -webkit-animation: pulse 1s infinite ease-in-out;
          animation: pulse 1s infinite ease-in-out;
}

.EventDetails-title:empty {
  width: 200px;
  height: 50px;
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.1)
  }
  50% {
    background-color: rgba(255, 255, 255, 0.3)
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1)
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.1)
  }
  50% {
    background-color: rgba(255, 255, 255, 0.3)
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1)
  }
}
.EventJoinForm {
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
}

.EventJoinForm > Input {
  margin-bottom: 15px;
}
.TravelModeSelect {
  color: black;
}

.TravelModeSelect-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.TravelModeSelect-item {
  padding: 10px;
  color: #696773;
  border: none;
  background-color: transparent;
}

.TravelModeSelect-item--selected {
  color: #009FB7;
}

.TravelModeSelect-bar {
  height: 4px;
}

.TravelModeSelect-loading {
  margin-top: 3px;
  border-radius: 2px;
  background-color: #009FB7;
  -webkit-animation: loading 3s linear infinite;
          animation: loading 3s linear infinite;
}

@-webkit-keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index:100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index:100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
.AttendeesList {
  display: flex;
  flex-direction: column;
}

.AttendeesList-modal {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 -2px 0 rgba(255,255,255,.05);
  background-color: rgb(34, 34, 34);
  position: absolute!important;
  bottom: 0;
  left: 0;
  padding: 10px 0 100px 0;
  height: calc(100vh - 110px - 108px - 400px); /* Subtract padding, header, and carousel */
  min-height: 50px;
  width: 100%;
  -webkit-animation: .2s 1 alternate slideInFromBelow;
          animation: .2s 1 alternate slideInFromBelow;
}

.AttendeesList-modal-overlay {
  background: none;
}

.AttendeesList-selected {

}

@-webkit-keyframes slideInFromBelow {
  0% {bottom: -100%;}
  100% {bottom: 0;}
}

@keyframes slideInFromBelow {
  0% {bottom: -100%;}
  100% {bottom: 0;}
}
.EventPageCarousel {
  position: relative;
}

.EventPageCarousel-item {
  min-width: 100%;
}

.EventPageCarousel-banner-hidden {
  display: none;
}

.EventPageCarousel-banner--left,
.EventPageCarousel-banner--right{
  position: absolute;
  padding: 5px 10px;
  z-index: 1000;
  background-color: rgb(67, 111, 189);
  top: -1px;
  font-size: 10pt;
}

.EventPageCarousel-banner--left {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.EventPageCarousel-banner--right {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.EventPageCarousel-content {
  display: flex;
}

.EventPageCarousel-content--translateLeft {
  /* Only supports two pages in the carousel */
  -webkit-transform: translate(0);
          transform: translate(0);
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}

.EventPageCarousel-content--translateRight {
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}
.BaseNode-flash,
.BaseNode-pulse {
  fill: white;
  fill-opacity: 0;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.BaseNode-flash {
  -webkit-animation-name: nodeFlash;
          animation-name: nodeFlash;
}

.BaseNode-pulse {
  -webkit-animation-name: nodePulse;
          animation-name: nodePulse;
}

/*.Ring-mask {*/
  /*fill: 'white';*/
/*}*/

@-webkit-keyframes nodePulse {
  from {
    r: 4%;
    opacity: 1;
  }
  to {
    r: 10%;
    opacity: 0;
  }
}

@keyframes nodePulse {
  from {
    r: 4%;
    opacity: 1;
  }
  to {
    r: 10%;
    opacity: 0;
  }
}

@-webkit-keyframes nodeFlash {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes nodeFlash {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.ZoomControls {
  position: absolute;
  right: 10px;
  background-color: white;
  bottom: 30px;
  height: 80px;
  width: 40px;
  border-radius: 2px;
}

.ZoomControls .ZoomControls-button {
  color: black;
  height: 50%;
  width: 100%;
  font-size: 24pt;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1), 0 -1px 0 rgba(0,0,0,0.01);
}
.EventPage {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 0 50px;
  min-height: 100%;
}

.EventPage-background {
  opacity: .4;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.EventPage-hasEnded {
  text-align: center;
  padding: 20px 0;
}
.SettingsPage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingsPage-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.SettingsPage-action {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
body {
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  color: white;
  background: rgb(34, 34, 34) no-repeat;
}

html {
  background-size: cover;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

a {
  color: white;
  text-decoration: none;
}


.Tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Tab-text {
  color: white;
  text-decoration: none;
  font-size: 8pt;
  color: #808080;
}

.Tab-text--active {
  color: white;
}

.Tab > a {
  text-decoration: none;
}
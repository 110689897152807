.NavBar {
  margin: 0 !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 45px;
  width: 100%;
  background-color: #292929;
  z-index: 1001; /* Higher than the selected Attendee modal */
}

.NavBar > * {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlacesAutocomplete-wrapper {
  display: flex;
  margin: 10px;
  padding: 0;
}

.PlacesAutocomplete-root {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.PlacesAutocomplete-input {
  border-radius: 10px;
  font-size: 12pt;
  padding: 7px;
  background-color: white;
  text-align: left;
  color: grey;
}

.PlacesAutocomplete-input,
.PlacesAutocomplete-autocompleteItem {
  border: none;
  outline: none;
}

.PlacesAutocomplete-autocompleteItem {
  color: black;
  font-size: 10pt;
  padding: 4px 0;
}

.PlacesAutocomplete-input {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0 rgba(0,0,0,0.02);
}

.PlacesAutocomplete-autocompleteContainer {
  margin-top: 10px;
  max-height: 0;
}

.PlacesAutocomplete-autocompleteContainer--nonEmpty,
.PlaceSuggestions {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.PlacesAutocomplete-autocompleteItemActive {
  background-color: #d3d3d3;
}

.PlacesAutocomplete-autocompleteItem-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.PlacesAutocomplete-autocompleteItem-mainText {
}

.PlacesAutocomplete-autocompleteItem-secondaryText {
  font-size: 10pt;
  color: lightgrey;
}